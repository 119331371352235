import React, { useContext, useState } from "react";
import { AppContext } from "./context/context";

import BooksDB from "./BooksDB";
import Reviews from "./Reviews";
import MyModal from "./ReviewsModal";

import LumalindawCover from "./assets/lumalindaw-cover-1.png";
import LamangCover from "./assets/lamang-cover-1.png";
import TaguwasiCover from "./assets/taguwasi-cover.png";
import Book4 from "./assets/Book4Teaser.jpg"

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function BooksPage() {
  const { setCurrentBook } = useContext(AppContext);
  const navigate = useNavigate();
  // console.log(window.location.href, window.location.pathname);

  const localBook = JSON.parse(localStorage.getItem("currentBook"));
  let currentBook = localBook.thisbook;
  const [mainImage, setMainImage] = useState(currentBook.photo);
  const [dropdownActive, setDropdownActive] = useState(false);

  const bookInfo = BooksDB.books;

  function setBook(thisbook) {
    localStorage.setItem("currentBook", JSON.stringify({ thisbook }));
    setCurrentBook(thisbook);
    // window.location = `${thisbook.bookPage}`;
    navigate(thisbook.bookPage);
    // setTimeout(
    //   () => (window.location = `http://localhost:3000${thisbook.bookPage}`),
    //   200
    // );
  }

  return (
    <div className="antialiased booksCarousel">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-6">
        <div className="flex flex-col md:flex-row -mx-4">
          <div className="md:flex-1 px-4">
            <div x-data="{ image: 1 }">
              <div className="h-1/2 md:h-fit rounded-lg bg-none mb-4">
                <Link to={"/books"} className="px-6 py-2 box-content font-semibold rounded-xl mb-2 bg-red-600 hover:bg-red-700 text-white text-center inline-flex items-center font-maragsa tracking-wide uppercase">Back to books</Link>
                <img
                  className="h-full max-h-80 mx-auto md:rounded-none rounded-t-lg mb-2"
                  src={mainImage}
                  alt={currentBook.title}
                />
                {/* <ul className="imageList flex justify-around space-x-3">
                  <li>
                    <button
                      className="h-24 w-24"
                      onClick={() => setBook(bookInfo[0])}
                    >
                      <img
                        src={LamangCover}
                        className="h-20 mx-auto"
                        alt={currentBook.title}
                      />
                    </button>
                  </li>
                  <li>
                    <button
                      className="h-24 w-24"
                      onClick={() => setBook(bookInfo[1])}
                    >
                      <img
                        className="h-20 mx-auto"
                        src={LumalindawCover}
                        alt={currentBook.title}
                      />
                    </button>
                  </li>
                  <li>
                    <button
                      className="h-24 w-24"
                      onClick={() => setBook(bookInfo[2])}
                    >
                      <img
                        className="h-20 mx-auto"
                        src={TaguwasiCover}
                        alt={currentBook.title}
                      />
                    </button>
                  </li>
                  <li>
                    <button
                      className="h-24 w-24"
                      onClick={() => setBook(bookInfo[3])}
                    >
                      <img
                        className="h-20 mx-auto"
                        src={Book4}
                        alt={currentBook.title}
                      />
                    </button>
                  </li>
                </ul> */}

              </div>
            </div>
          </div>
          <div className="md:flex-1 px-4">
            <p className="mb-2 leading-tight tracking-tight text-white text-2xl md:text-5xl">
              {currentBook.title}
            </p>
            <p className="text-gray-500 text-sm">
              By{" "}
              <a href="/author" className="text-yellow-600 hover:underline">
                Herbert Deleon
              </a>
            </p>

            <p className="text-white">{currentBook.description}</p>

            <div className="inline-flex py-4 space-x-4 items-stretch">
              <div className="inline-block">
                <button
                  onClick={() => setDropdownActive(!dropdownActive)}
                  className="px-6 py-2 box-content font-semibold rounded-xl bg-yellow-600 hover:bg-yellow-700 text-white text-center inline-flex items-center font-maragsa tracking-wide uppercase"
                  type="button"
                >
                  Buy Now
                  <svg
                    className="w-4 h-4 ml-2"
                    aria-hidden="true"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>

                {dropdownActive ? (
                  <div
                    id="dropdown"
                    className="z-10 bg-white divide-y divide-gray-100 rounded-xl shadow dark:bg-green-900"
                  >
                    <ul
                      className="py-2 text-sm text-green-700 dark:text-gray-200"
                      aria-labelledby="dropdownDefaultButton"
                    >
                      <li>
                        <a
                          href={currentBook.link}
                          target="_blank"
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          rel="noreferrer"
                        >
                          Amazon
                        </a>
                      </li>
                      
                      <li>
                        {currentBook.HotTropiksLink ? (
                          <a
                            href={currentBook.HotTropiksLink}
                            target="_blank"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            rel="noreferrer"
                          >
                          Hot Tropiks  
                          </a>
                        ) : (
                          <a className="invisible"></a>
                        )}
                      </li>
                      <li>
                        {currentBook.LazadaPBLink ? (
                          <a
                            href={currentBook.LazadaPBLink}
                            target="_blank"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            rel="noreferrer"
                          >
                            Lazada Paperback
                          </a>
                        ) : (
                          <a className="invisible"></a>
                        )}
                      </li>
                      <li>
                        {currentBook.LazadaHCLink ? (
                          <a
                            href={currentBook.LazadaHCLink}
                            target="_blank"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            rel="noreferrer"
                          >
                            Lazada Hardcover
                          </a>
                        ) : (
                          <a className="invisible"></a>
                        )}
                      </li>
                      <li>
                        {currentBook.ShopeePBLink ? (
                          <a
                            href={currentBook.ShopeePBLink}
                            target="_blank"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            rel="noreferrer"
                          >
                            Shopee Paperback
                          </a>
                        ) : (
                          <a className="invisible"></a>
                        )}
                      </li>
                      <li>
                        {currentBook.ShopeeHCLink ? (
                          <a
                            href={currentBook.ShopeeHCLink}
                            target="_blank"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                            rel="noreferrer"
                          >
                            Shopee Hardcover
                          </a>
                        ) : (
                          <a className="invisible"></a>
                        )}
                      </li>
                      <li>
                        <a
                          href={currentBook.KoboLink}
                          target="_blank"
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          rel="noreferrer"
                        >
                          Kobo
                        </a>
                      </li>
                      <li>
                        <a
                          href={currentBook.BarnesandnobleLink}
                          target="_blank"
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          rel="noreferrer"
                        >
                          Barnes & Noble
                        </a>
                      </li>
                      <li>
                        <a
                          href={currentBook.AppleBooks}
                          target="_blank"
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          rel="noreferrer"
                        >
                          Apple Books
                        </a>
                      </li>
                      <li>
                        <a
                          href={currentBook.GoogleplayLink}
                          target="_blank"
                          className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          rel="noreferrer"
                        >
                          Google Play
                        </a>
                      </li>

                    </ul>
                  </div>
                ) : (
                  <div
                    id="dropdown"
                    className="hidden z-10 bg-white divide-y divide-gray-100 rounded-xl shadow dark:bg-gray-700"
                  >
                    <ul
                      className="py-2 text-sm text-gray-700 dark:text-gray-200"
                      aria-labelledby="dropdownDefaultButton"
                    >
                    </ul>
                  </div>
                )}
              </div>

              <MyModal />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-white">
        <p className="text-bold text-center text-2xl md:text-5xl pt-4">
          Reviews
        </p>
        <Reviews />
      </div>
    </div>
  );
}
